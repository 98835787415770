import React, { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import AllGamesData from "../Asset/game.json";
import { FaArrowLeft } from "react-icons/fa6";
import GameCard from "./GameCard";

export default function Single() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState({});
  const iframeRef = useRef(null);
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const slugParam = searchParams.get("slug");
    const game = AllGamesData.find((game) => game.slug === slugParam);
    setGameData(game);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timer);
  }, [location.search]);

  const handleOpenIframe = () => {
    setIframeSrc(gameData.gameurl);
    setIsIframeOpen(true);
  };

  const handleCloseIframe = () => {
    setIframeSrc(""); // Clear the src to stop the game
    setIsIframeOpen(false);
  };

  return (
    <>
      {loading && <Loader />}
      {!loading && gameData ? (
        <main>
          <div className="w-full">
            <div
              className={`fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-75 ${
                isIframeOpen ? "block" : "hidden"
              }`}
            >
              <iframe
                ref={iframeRef}
                src={iframeSrc}
                title={gameData.title}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="w-11/12 h-5/6 mt-10 mx-auto border-none"
              ></iframe>
              <button
                onClick={handleCloseIframe}
                className="absolute top-4 left-4 bg-white text-black rounded-full p-2 z-10"
              >
                <FaArrowLeft />
              </button>
            </div>

            <div className="md:grid grid-cols-12 mt-[5px] gap-4">
              <div className="xl:col-span-1"></div>
              <div className="xl:col-span-10 w-full col-span-12 relative shadow-gray-500">
                <div className="bg-white h-[32.5rem] rounded-xl relative">
                  <div className="h-full opacity-20">
                    <img
                      alt={gameData.title}
                      loading="eager"
                      decoding="async"
                      className="w-full h-full rounded-2xl bg-cover text-transparent"
                      src={gameData.banner}
                    />
                  </div>
                  <div className="w-full h-full text-white flex flex-col justify-center items-center absolute top-0 left-0 z-[1]">
                    <div className="text-center">
                      <img
                        alt={gameData.title}
                        loading="eager"
                        decoding="async"
                        className="m-auto w-32 rounded-2xl  text-transparent"
                        src={gameData.logo}
                      />

                      <p className="text-xl font-bold pt-3 shadow-gray-200 text-black">
                        {gameData.title}
                      </p>
                      <button
                        className="rounded-full shadow-black hover:scale-110 fade-in transition-all font-bold px-6 py-1 mt-3 text-lg align-middle gap-2 text-white bg-sky-500"
                        onClick={handleOpenIframe}
                      >
                        Play Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid gap-4 grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 my-6">
              {AllGamesData.map((game) => (
                <GameCard key={game.slug} gameData={game} />
              ))}
            </div>
          </div>
        </main>
      ) : (
        <p>Game not found</p>
      )}
    </>
  );
}
