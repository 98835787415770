import React, { useEffect, useState } from "react";
import Loader from "./Loader";
import AllGamesData from "../Asset/game.json";
import GameCard from "./GameCard";

export default function Home() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="grid gap-4 grid-cols-2 md:grid-cols-4 2xl:grid-cols-6 mb-12">
            {AllGamesData.map((game) => (
              <GameCard key={game.slug} gameData={game} />
            ))}
          </div>
        </>
      )}
    </>
  );
}
